<template>
  <index-page-title>
    <template #title>
      <div class="flex">
        <div
          class="flex items-center justify-center h-8 bg-v-ui-root-add-another-2 lg:px-6 px-3 rounded-full rounded-bl-none xl:h-12"
        >
          <span
            class="text-v-ui-text-monochrome text-base font-medium xl:text-xl"
          >
            {{ $t('domains.domains.pages.subscription.issue-theme') }}
          </span>
        </div>
      </div>

      <i18n
        path="domains.domains.pages.subscription.title"
        tag="span"
        class="block text-v-ui-root-monochrome-0 xl:mt-3 mt-4"
      >
        <br />
      </i18n>
    </template>

    <template #description>
      <i18n
        path="domains.domains.pages.subscription.description"
        tag="span"
        class="text-v-ui-root-monochrome-5"
      >
        <br class="xl:hidden" />
        <br />
      </i18n>
    </template>
  </index-page-title>
</template>

<script>
import IndexPageTitle from '../index-page-title/IndexPageTitle';

export default {
  name: 'SubscriptionTitle',
  components: { IndexPageTitle }
};
</script>

<style scoped></style>
